.root {
	position: relative;
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	text-align: center;
	padding: 6.24vw 0;
	margin-bottom: -6vw;
}

.title {
	font-size: clamp(160px, 24.13vw, 24.13vw);
	font-family: var(--title-font);
	line-height: 98%;
	margin-bottom: clamp(36px, 4.56vw, 4.56vw);
	position: relative;
}

.desc {
	font-size: var(--fzDesc);
	margin-bottom: clamp(24px, 1.26vw, 1.26vw);
}

.currentTime {
	font-size: clamp(20px, 2.5vw, 2.5vw);
	position: absolute;
	right: 0;
	bottom: -10%;
	line-height: 100%;
}

.bg {
	background: linear-gradient(
		0deg,
		#a2a2a9 12.63%,
		rgba(162, 162, 169, 0) 92.04%
	);
	position: absolute;
	bottom: -10vh;
	width: 50%;
	height: 76%;
	z-index: -1;
	display: flex;
	justify-content: center;
	animation: redirectAnim 8s ease;
}

@keyframes redirectAnim {
	0% {
		width: 50%;
	}
	100% {
		width: 100%;
	}
}

@media screen and (max-width: 768px) {
	.root {
		padding: 150px 0;
	}
}
