.root {
	overflow: hidden;
	height: 100vh;
	margin: var(--content-padding) 0;
}

.wrapper_top,
.wrapper_bottom {
	display: flex;
	flex-wrap: nowrap;
	gap: 2vw;
}

.wrapper_bottom {
	margin-top: 2vw;
	transform: translateX(-150%);
}

.item {
	width: 100vw;
	height: 100vh;
	flex-shrink: 0;
}

.item img {
	width: 100%;
	height: 100%;
	object-fit: cover;
}

@media screen and (max-width: 768px) {
	.root,
	.item {
		height: auto;
	}

	.wrapper_top {
		flex-direction: column;
	}
	.wrapper_bottom {
		display: none;
	}
}
