.root {
	position: sticky;
	top: clamp(8px, 0.63vw, 0.63vw);
	padding: 0 var(--side-padding);
	display: flex;
	align-items: center;
	justify-content: space-between;
	z-index: 1000;
}

.header__item {
	flex: 1;
}

.header__item:nth-child(2) {
	text-align: center;
}

.header__item:nth-child(3) {
	display: flex;
	justify-content: flex-end;
}

.header__item_inner {
	display: inline-block;
}

@media screen and (max-width: 568px) {
	.header__item {
		flex: unset;
	}

	.header__item:nth-child(1) {
		display: none;
	}
}
