.root {
	margin: 0 0 var(--content-padding);
	overflow: hidden;
}

.items {
	display: flex;
	flex-wrap: nowrap;
	align-items: center;
	position: relative;
}

.itemsMobile {
	display: none;
}

.more {
	display: flex;
	align-items: center;
	justify-content: center;
	flex-direction: column;
	width: 22vw;
	height: 100%;
	flex-shrink: 0;
	/* font-size: var(--fzName); */
	font-size: 2.5vw;
	font-family: var(--title-font);
	text-align: center;
}

.more svg {
	margin-top: 26px;
}

@media screen and (max-width: 1024px) {
	.items {
		display: none;
	}

	.itemsMobile {
		display: flex;
		flex-direction: column;
	}
}
