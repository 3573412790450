.form {
	overflow: hidden;
}

.input {
	display: flex;
	align-items: center;
	gap: 1.25vw;
}

.label {
	font-size: var(--fzTitleBlockSmall);
	font-family: var(--title-font);
	flex-shrink: 0;
}

.input input::placeholder {
	font-size: var(--fzDesc);
}

.input input {
	font-size: var(--fzTitleBlockSmall);
	font-family: var(--title-font);
	text-align: center;
	width: 100%;
	border-bottom: 1px solid var(--gray-color);
}

.submit_btn {
	background-color: var(--black-color);
	color: #fff;
	text-align: center;
	width: 100%;
	padding: 1.2vw;
	border-radius: var(--radius);
	margin-top: 2.52vw;
	font-size: var(--fzDesc);
}

.submit_btn:disabled {
	cursor: unset;
	background-color: chartreuse;
	color: var(--black-color);
}

.input .error {
	border-bottom: 1px solid #e32636;
}

.hide {
	display: none;
}

@media screen and (max-width: 768px) {
	.input {
		flex-direction: column;
		align-items: flex-start;
		margin-bottom: 6.1vw;
		gap: 1.6vw;
	}

	.input input {
		padding: 2.12vw 0 3vw;
	}

	.input input,
	.input input::placeholder {
		text-align: left;
	}

	.submit_btn {
		padding: 3.4vw;
		margin-top: 0;
		border-radius: 4.4vw;
	}
}
