.listWrapper {
	overflow-x: hidden;
}

.desc {
	display: block;
	font-size: var(--fzSmall);
	color: var(--gray-color);
}

@media screen and (max-width: 768px) {
	.list {
		margin-top: 20px;
	}
}
