.title,
.title_small {
	font-family: var(--title-font);
	line-height: 122.94%;
	display: flex;
}

.title {
	font-size: var(--fzTitleBlockBig);
}

.title_small {
	font-size: var(--fzTitleBlockSmall);
}

.title span,
.title_small span {
	color: var(--gray-color);
}

.leftside {
	max-width: clamp(290px, 20vw, 20vw);
	display: flex;
	flex-direction: column;
	justify-content: space-between;
	height: 100%;
	padding-top: 0.88vw;
}

.desc {
	color: var(--gray-color);
	margin-bottom: 8px;
}

.item__link {
	display: flex;
	align-self: flex-start;
}

.desc,
.text,
.subText,
.list__item {
	font-size: var(--fzDesc);
}

.list__item {
	margin-bottom: clamp(6px, 0.52vw, 0.52vw);
}

.list__item:last-of-type {
	margin-bottom: 0;
}

.rootMobile {
	display: none;
}

.leftsideMobile {
	margin-top: 18px;
}

.leftsideMobile .subText {
	display: inline-block;
}

.leftsideMobile .list {
	display: flex;
	gap: 12px;
	overflow-x: auto;
	overflow-y: hidden;
}

.leftsideMobile .list__item {
	margin-bottom: 0;
}

.leftsideMobile .desc {
	display: inline-block;
}

.leftsideMobile .links,
.leftsideMobile .subText {
	margin-top: 2.82vw;
}

@media screen and (max-width: 1024px) {
	.root .container__item:nth-child(1) {
		flex-basis: 40%;
	}
}

@media screen and (max-width: 900px) {
	.root {
		display: none;
	}

	.rootMobile {
		display: block;
	}
}
