.root,
.root_small {
	position: relative;
	width: 100vw;
	height: 100vh;
	flex-shrink: 0;
}

.root_small {
	width: 50%;
	height: 56vw;
}

.wrapper,
.wrapper_notFull {
	display: flex;
	align-items: flex-end;
	justify-content: space-between;
	position: absolute;
	bottom: 0;
	width: 100%;
	color: #fff;
	padding: 2.08vw var(--side-padding) 5.2vw;

	@media screen and (max-width: 768px) {
		padding: 2.08vw var(--side-padding) 7.2vw;
	}
}

.wrapper_notFull {
	height: 100%;
	top: 2.08vw;

	@media screen and (max-width: 768px) {
		top: 4.08vw;
	}
}

.name {
	font-size: var(--fzTitleBlockSmall);
	font-family: var(--title-font);
}

.services {
	display: flex;
	gap: clamp(6px, 0.8vw, 0.8vw);
}

.service__item {
	padding: 0.72vw 2.18vw;
	border: 1px solid #fff;
	border-radius: var(--radius);
	font-size: var(--fzSmall);
}

.image {
	height: 100%;
}

.image img {
	width: 100%;
	height: 100%;
	object-fit: cover;
}

.date {
	font-size: var(--fzDesc);
}

.rightside {
	display: flex;
	flex-direction: column;
	align-items: flex-end;
	gap: 0.32vw;
}

.top {
	position: absolute;
	top: 0;
}

.bottom {
	display: flex;
	align-items: flex-end;
	justify-content: space-between;
	width: 100%;
}

@media screen and (max-width: 1024px) {
	.root_small {
		width: 100%;
		height: 56vw;
	}
}

@media screen and (max-width: 768px) {
	.root_small {
		height: 100vw;
	}
}
