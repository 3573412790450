.root {
	padding: 3.92vw 0 0;
	z-index: 2;
	height: 100vh;
}

.title,
.title_small,
.title_smaller {
	font-family: var(--title-font);
	line-height: 98%;
	text-align: center;
	overflow: hidden;
}

.title__wrapper {
	position: relative;
	z-index: 5;
	width: 100vw;
}

.title {
	font-size: var(--fzTitleBig);
}

.title_small {
	font-size: var(--fzTitleMedium);
}

.title_smaller {
	font-size: var(--fzTitleSmall);
}

@media screen and (max-width: 768px) {
	.root {
		padding: 40px 0 0;
		height: auto;
	}

	.title__wrapper {
		margin-bottom: 16px;
	}
}
