.root {
	margin: 0 0 var(--content-padding);
	height: 100vh;
	overflow: hidden;
	text-align: right;
}

.image {
	border-radius: var(--radius);
	width: 68%;
	height: 68%;
	max-height: 100vh;
	object-fit: cover;
}

@media screen and (max-width: 768px) {
	.root {
		height: 50vw;
	}

	.image {
		width: 100%;
		height: 100%;
	}
}
