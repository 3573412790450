.root {
	padding-top: var(--content-padding);
}

.footer__wrapper,
.footer__wrapper_mobile {
	background-color: var(--black-color);
	padding: clamp(34px, 4.58vw, 4.58vw) 0;
	color: #fff;
}

.footer__wrapper {
	border-radius: var(--radius);
}

.footer__wrapper_mobile {
	display: none;
	border-radius: 2.8vw;
}

.footer__item {
	margin-bottom: 5.22vw;
}

.footer__item:last-of-type {
	margin-bottom: 0;
}

.footer__item:last-of-type > div {
	align-items: flex-end;
}

.footer__links {
	margin-bottom: 1.26vw;
}

.footer__link {
	display: flex;
	margin-bottom: 0.64vw;
}
.footer__link:last-of-type {
	margin-bottom: 0;
}

.footer__item_header {
	display: flex;
	flex-direction: column;
	justify-content: space-between;
	height: 100%;
}

.footer__btn {
	text-align: left;
}

.footer__squarelinks {
	display: flex;
	gap: clamp(10px, 1.34vw, 1.34vw);
}

.footer__bottom {
	color: #fff;
	padding: 0 var(--side-padding);
	position: relative;
	margin: 4.18vw 0;
}

.footer__squarelink,
.footer__squarelink a {
	position: absolute;
	left: 0;
	bottom: 0;
	width: 26vw;
	height: 20vw;
}

.footer__bottom svg {
	width: 100%;
}

.footer__privacy {
	display: flex;
	justify-content: space-between;
	padding: 0 var(--side-padding) 0.94vw;
}

.footer__privacy_item {
	flex: 1;
	display: flex;
	font-size: var(--fzDesc);
}

.footer__privacy_item:nth-child(2) {
	justify-content: center;
}
.footer__privacy_item:nth-child(3) {
	justify-content: flex-end;
}

.footer__btn,
.footer__time span {
	font-size: 6.66vw;
}

.footer__time {
	font-size: 0.82vw;
}

.footer__btn span {
	display: flex;
	align-items: center;
}

@media screen and (max-width: 768px) {
	.footer__wrapper {
		display: none;
	}

	.footer__wrapper_mobile {
		display: block;
	}

	.footer__btn {
		font-size: 11.66vw;
	}

	.footer__btn span {
		justify-content: center;
	}

	.footer__item_footer {
		display: flex;
		justify-content: space-between;
		margin-top: 7.04vw;
	}

	.footer__links {
		display: none;
	}

	.footer__squarelinks {
		flex-basis: 100%;
		flex-wrap: wrap;
	}

	.footer__squarelink {
		padding-bottom: 45%;
	}

	.footer__privacy {
		flex-direction: column-reverse;
		align-items: center;
		gap: 4px;
	}

	.footer__privacy a {
		font-size: 14px;
	}
}
