.link,
.link_black {
	min-width: clamp(100px, 11.04vw, 11.04vw);
	min-height: clamp(130px, 11.04vw, 11.04vw);
	border-radius: var(--radius);
	display: flex;
	align-self: flex-end;
	align-items: flex-end;
	justify-content: space-between;
	background: rgba(255, 255, 255, 0.3);
	padding: clamp(8px, 1.04vw, 1.04vw);
	font-size: var(--fzDesc);
	position: relative;
}

.link span,
.link_black span {
	position: absolute;
	top: clamp(8px, 1vw, 1vw);
	right: clamp(8px, 1vw, 1vw);
}

.link svg,
.link_black svg {
	width: clamp(12px, 0.96vw, 0.96vw);
	height: clamp(12px, 0.96vw, 0.96vw);
}

.link {
	background: rgba(10, 10, 10, 0.3);
	backdrop-filter: blur(8px);
}

@media screen and (max-width: 768px) {
	.link {
		display: none;
	}

	.link_black {
		width: 48%;
		height: 48%;
	}
}
