.root {
	display: flex;
	justify-content: space-between;
}

.phone {
	flex-basis: 20%;
	position: relative;
}

.phone img {
	width: 100%;
	height: 100%;
	object-fit: cover;
}

.phone__inner {
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	z-index: -1;
	border-radius: 4.17vw;
	overflow: hidden;
	padding: 0.9vw;
}

@media screen and (max-width: 768px) {
	.phone {
		flex-basis: 32%;
	}
}
