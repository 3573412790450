.menu__btn,
.menu__btn_active {
	position: relative;
	display: flex;
	align-items: center;
	gap: clamp(6px, 0.52vw, 0.52vw);
	z-index: 1002;
	transition: var(--main-transition);
	font-size: var(--fzSmall);
}

.menu__btn_active {
	transform: translate(clamp(-40px, -1vw, -1vw), 22px);

	@media screen and (max-width: 768px) {
		/* transform: translate(-4.8vw, 3.8vw); */
	}
}

.menu__btn_icon {
	width: clamp(32px, 2.29vw, 2.29vw);
	height: clamp(32px, 2.29vw, 2.29vw);
	transition: var(--smooth-transition);
}

.menu__btn_active .menu__btn_icon {
	transform: rotate(45deg);
	transform-origin: center;
}

.menu__bg {
	position: fixed;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	background-color: rgba(0, 0, 0, 0.4);
	z-index: 1000;
	display: flex;
	justify-content: flex-end;
	opacity: 0;
	visibility: hidden;
	transition: 0.45s ease;
}

.menu_usage .menu__bg {
	position: fixed;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	background-color: rgba(0, 0, 0, 0.4);
	z-index: 1000;
	display: flex;
	justify-content: flex-end;
	opacity: 1;
	visibility: visible;
	transition: var(--main-transition);
}

.menu__container {
	position: fixed;
	top: 2px;
	right: 2px;
	border-radius: var(--radius);
	background-color: var(--bg-color);
	width: 100%;
	height: 100%;
	max-width: clamp(400px, 28.65vw, 28.65vw);
	max-height: clamp(480px, 36.25vw, 36.25vw);
	margin: 16px 3vw;
	padding: clamp(24px, 1.46vw, 1.46vw) clamp(18px, 1.67vw, 1.67vw);
	transform: scale(0.01);
	transform-origin: top right;
	transition: var(--main-transition);
	z-index: 1001;
	display: flex;
	flex-direction: column;
	justify-content: space-between;
	will-change: transform;
	opacity: 0;
	visibility: hidden;
}

.menu_usage .menu__container {
	transform: scale(1);
	opacity: 1;
	visibility: visible;
}

.menu__links {
	margin: clamp(42px, 2vw, 2vw) 0;
}

.menu__link {
	margin-bottom: clamp(18px, 0.64vw, 0.64vw);
	font-size: var(--fzName);
}

.menu__link a {
	width: 100%;
}

.menu__link:last-of-type {
	margin-bottom: 0;
}

.menu__footer {
	display: flex;
	align-items: flex-end;
	justify-content: space-between;
}

.menu__place {
	color: var(--gray-color);
	font-size: var(--fzSmall);
}

.social__links {
	display: flex;
	flex-direction: column;
	gap: clamp(6px, 0.32vw, 0.32vw);
}

.social__links a {
	justify-content: space-between;
	min-width: clamp(60px, 7.28vw, 7.28vw);
}

@media screen and (max-width: 768px) {
	.menu__container {
		margin: unset;
		max-width: 100%;
		width: calc(100% - 20px);
		max-height: unset;
		height: auto;
		top: 10px;
		left: 10px;
	}
}
