.headline {
	display: flex;
	justify-content: space-between;
	margin-bottom: clamp(20px, 1.98vw, 1.98vw);
}

.desc {
	font-size: var(--fzDesc);
}

.name {
	font-size: var(--fzTitleBlockSmall);
	font-family: var(--title-font);
	display: flex;
	align-items: center;
	gap: 1.56vw;
	line-height: 100%;
}

.image {
	border-radius: var(--radius);
	width: 100%;
	height: 100%;
	object-fit: cover;
}

@media screen and (max-width: 768px) {
	.headline {
		flex-direction: column;
	}

	.desc {
		margin-bottom: 6px;
	}

	.name {
		justify-content: space-between;
	}

	.link {
		display: inline-block;
		height: 100vw;
	}
}
