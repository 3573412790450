.options {
	display: flex;
	align-items: center;
	gap: clamp(8px, 0.52vw, 0.52vw);
	margin-top: 1.25vw;
}

.option,
.option_active {
	font-size: var(--fzDesc);
	padding: clamp(14px, 0.84vw, 0.84vw) clamp(14px, 2.2vw, 2.2vw);
	border-radius: var(--radius);
	color: var(--gray-color);
	border: 1px solid var(--gray-color);
	cursor: pointer;
	font-family: var(--title-font);
	transition: var(--main-transition);
}

.option_active {
	color: #fff;
	background-color: var(--black-color);
}

@media screen and (max-width: 768px) {
	.options {
		flex-direction: column;
		flex-wrap: wrap;
		width: 100%;
	}

	.option,
	.option_active {
		border-radius: 4vw;
		width: 100%;
	}
}
