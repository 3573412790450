.modalContainer {
	width: 26vw;
	height: 20vw;
	display: flex;
	align-items: center;
	justify-content: center;
	position: absolute;
	overflow: hidden;
	pointer-events: none;
}

.modalSlider {
	height: 100%;
	width: 100%;
	position: absolute;
	transition: top 0.5s cubic-bezier(0.76, 0, 0.24, 1);
}

.modal {
	position: relative;
	height: 100%;
	display: flex;
	align-items: center;
	justify-content: center;
}

.modal img {
	width: 100%;
	height: 100%;
	object-fit: cover;
}
